import React, { useState, useEffect } from "react";
import { Form, Input, Tabs, Row } from "antd";
import Button from '../../components/common/ec/Button';
import { t } from '../../helpers/translation';
import { phoneNumberRegExp } from '../../utils/phoneNumberRegExp'
const { TabPane } = Tabs

const NewSignUpForm = ({
  csrf_token,
  registrerForm,
  loginForm,
  onFinishSignUp,
  onFinishSignIn,
  incentiveStaffId,
  incentiveStoreHashCode,
  incentiveStaffHashCode,
  isLoading
}) => {
  const [key, setKey] = useState('signup')
  const params = (new URL(document.location)).searchParams;
  let action = params?.get('action') || key;

  useEffect(() => {
    setKey(action)
  }, [])

  const onValidate = (values) => {
    onFinishSignUp(values);
  };

  const handleChange = key => {
    setKey(key)
  }

  return (
    <Tabs className="custom-tab" activeKey={key} onChange={handleChange}>
      <TabPane tab={t('new_user.member_registration')} key="signup">
        <Form
          layout="vertical"
          form={registrerForm}
          name="register"
          onFinish={onValidate}
          scrollToFirstError
        >
          <Form.Item
            name="phone"
            label={t("new_user.tel")}
            rules={[
              {
                required: true,
                message: t("new_user.enter_tel"),
              },
              {
                pattern: phoneNumberRegExp,
                message: t("new_user.please_input_valid_phone_number")
              },
            ]}
            hasFeedback
          >
            <Input className="custom-input" type="text" />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("new_user.password")}
            rules={[
              {
                required: true,
                message: t("new_user.enter_password"),
              },
              {
                min: 8,
                message: t("new_user.min_length {{length}}", { length: 8 }),
              },
            ]}
            hasFeedback
          >
            <Input.Password className="custom-input" placeholder={t('new_user.password_place_holder')} />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            label={t("new_user.password_confirmation")}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                  message: t("new_user.enter_password_confirmation"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(t('new_user.password_not_match'))
                  );
                },
              }),
            ]}
          >
            <Input.Password className="custom-input" placeholder={t('new_user.password_place_holder')} />
          </Form.Item>

          <Form.Item
            name="incentive_store_hash_code"
            initialValue={incentiveStoreHashCode}
            hidden={true}
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="incentive_staff_hash_code"
            initialValue={incentiveStaffHashCode}
            hidden={true}
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Row>
                <Button 
                  variant="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("new_user.member_registration")}
                </Button>
              </Row>
            )}
          </Form.Item>
        </Form>
      </TabPane>
      <TabPane tab={t('new_user.login')} key="signin">
        <Form
          layout="vertical"
          form={loginForm}
          name="signIn"
          onFinish={onFinishSignIn}
          scrollToFirstError
        >
          <Form.Item
            name="phone_number_mobile"
            label={t("new_user.tel")}
            rules={[
              {
                required: true,
                message: t("new_user.enter_tel"),
              },
              {
                pattern: phoneNumberRegExp,
                message: t("new_user.please_input_valid_phone_number")
              },

            ]}
            hasFeedback
          >
            <Input 
              className="custom-input" 
              type="text" 
              />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("new_user.password")}
            rules={[
              {
                required: true,
                message: t("new_user.enter_password"),
              },
              {
                min: 8,
                message: t("new_user.min_length {{length}}", { length: 8 }),
              },
            ]}
            hasFeedback
          >
            <Input.Password className="custom-input" />
          </Form.Item>

          <Form.Item>
            <a href="/users/password/new">{window.i18n.t("new_user.forget_password")}</a>
          </Form.Item>
          <Form.Item>
            <Row>
              <Button
                htmlType="submit"
                loading={isLoading}>
                {window.i18n.t("new_user.login")}
              </Button>
            </Row>
          </Form.Item>
        </Form>
        <div className="or-text">
          <span>
            OR
          </span>
        </div>
        <form action="/users/auth/line" method="post" className="login-with-line mb-6">
          <input type="hidden" name="authenticity_token" value={csrf_token} />
          <button type="submit" disabled={isLoading}>{t("new_user.login_with_line")}</button>
        </form>
      </TabPane>
    </Tabs>
  );
};

export default NewSignUpForm;
