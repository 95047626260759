import consumer from "./consumer"
import { notification } from 'antd'
import { t } from '../../javascript/helpers/translation'
import { removeItem, setItem } from '../../javascript/helpers/localStorage';

const POS_WITHDRAWAL = '/dashboard/pos/withdrawals'
const POS_DEPOSIT = '/dashboard/deposits'
const POS_COLLECT = '/dashboard/pos_collects'

consumer.subscriptions.create('PosChannel', {
  received(data) {
    if((window.location.pathname == POS_COLLECT && data.type == 'POS_COLLECT') || 
        (window.location.pathname == POS_WITHDRAWAL && data.type == 'POS_WITHDRAWAL') || 
        (window.location.pathname == POS_DEPOSIT && data.type == 'POS_DEPOSIT')) {

      if(data.response == 'success'){
        window.location.reload()
      }
      else if(data.response == 'error'){
        const ngContent = data.ng_content;

        if(ngContent) {
          notification.error({
            message: t(`settlement.cash_machine_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.cash_machine_errors.undefined`),
          });
        }
      }
    }
    else if(window.location.pathname == `/dashboard/settlements/${data.settlement_id}` && data.type == 'POS_SETTLEMENT'){
      const params = new URLSearchParams(window.location.search);

      if(data.response == 'success') {
        if(params.has('schedule') && params.has('store')) {
          removeItem('SCHEDULE-RES-ID')
          setItem('SCHEDULE-RES-ID', data.reservation_id)
          setItem('SCHEDULE-DATE', data.reservation_date_visit_at)
          window.location.href = `/dashboard/stores/${params.get('store')}/schedules`
        } else if(data.from_settlement_history) {
          window.location.href = '/dashboard/settlement_histories'
        } else {
          window.location.href = '/dashboard/pos/settlements'
        }
      } else {
        const ngContent = data.ng_content;

        if(ngContent) {
          notification.error({
            message: t(`settlement.cash_machine_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.cash_machine_errors.undefined`),
          });
        }
      }
    }
    else if(window.location.pathname == `/dashboard/black_invoices/${data.transaction_no}` && data.type == 'POS_SETTLEMENT'){
      if(data.response == 'success') {
        window.location.href = '/dashboard/settlement_histories'
      } else {
        const ngContent = data.ng_content;

        if(ngContent) {
          notification.error({
            message: t(`settlement.cash_machine_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.cash_machine_errors.undefined`),
          });
        }
      }
    }
    else if(window.location.pathname == `/dashboard/unpaid/${data.settlement_id}/settlement` && data.type == 'POS_SETTLEMENT'){
      if(data.response == 'success') {
        if(data.spot_usage) {
          window.location.href = '/dashboard/unpaid_spot_usage_fee'
        } else {
          window.location.href = '/dashboard/unpaid'
        }
      } else {
        const ngContent = data.ng_content;

        if(ngContent) {
          notification.error({
            message: t(`settlement.cash_machine_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.cash_machine_errors.undefined`),
          });
        }
      }
    }
    else if (window.location.pathname == `/dashboard/settlement_details/${data.settlement_id}` && data.type == 'RECEIPT_REPRINTED') {
      window.dispatchEvent(new CustomEvent("PosChannel::RECEIPT_REPRINTED", {
        detail: { data }
      }));
      if (data.response != 'success') {
        const ngContent = data.ng_content;
        if (ngContent) {
          notification.error({
            message: t(`settlement.receipt_reprint_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.receipt_reprint_errors.undefined`),
          });
        }
      }
    }
    else if(window.location.pathname == `/dashboard/settlement_details/${data.settlement_id}` && data.type == 'OFFICIAL_RECEIPT_PRINTED'){
      window.dispatchEvent(new CustomEvent("PosChannel::OFFICIAL_RECEIPT_PRINTED", {
        detail: { data }
      }));
      if(data.response != 'success') {
        const ngContent = data.ng_content;
        if(ngContent) {
          notification.error({
            message: t(`settlement.official_receipt_print_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.official_receipt_print_errors.undefined`),
          });
        }
      }
    }
  },

  subscribed: function() {
    return this.perform('subscribed');
  }
});
